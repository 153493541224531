.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    border-radius: 10px;
    text-decoration: none;
}

.card:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
}

.card h6:hover, h5:hover, p:hover {
    text-decoration: underline !important;
}