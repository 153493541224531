.modal-overlay {
    position: relative;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    transition: transform 0.3s ease;
    z-index: 500;
}

.modal-content {
    background: white;
    width: 24.646rem;
    height: 100%;
    display: flex;
    flex: 1;
    padding: 3rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    position: relative;
}

.modal-overlay .modal-content {
    transform: translateX(0);
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: black !important;
}

@media (max-width: 55rem) {
    .modal-overlay {
        position: absolute;
        left: 0;
        background-color: white;
        width: 25.875rem;
        height: 48.594rem;
    }

    .modal-content {
        width: 25.875rem;
        height: 48.594rem;
    }
}

@media (max-width: 48rem) {
    .modal-overlay {
        width: 23.767rem;
    }

    .modal-content {
        width: 23.767rem;
    }
}

@media (max-width: 32rem) {
    .modal-overlay {
        width: 22.714rem !important;
        height: 48.594rem !important;
    }

    .modal-content {
        width: 22.714rem !important;
        height: 48.594rem !important;
    }
}

@media (max-width: 26rem) {
    .modal-overlay {
        width: 22.714 !important;
        height: 52rem !important;
    }

    .modal-content {
        width: 22.714 !important;
        height: 52rem !important;
    }
}