.tests-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5rem;
}

.tests-section {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.tests-section .bullet li {
    line-height: 1.75rem !important;
}

.tests-section .bullet:hover {
    text-decoration: none;
}

.mini-section {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.t-img {
    width: 100%;
    height: 12rem;
    object-fit: cover;
}

.integration-row {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.integration-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: calc((100% - 10rem) / 3);
    text-align: center;
}

.research-container {
    display: grid;
    grid-template-columns: 7fr 2.5fr;
    gap: 6rem;
}

.bolded:hover {
    text-decoration: underline !important;
}

.right-container-mod .top-icon-row h4:hover {
    text-decoration: underline !important;
}

@media (max-width: 96rem) {
    .tests-container {
        gap: 3rem;
    }

    .research-container {
        grid-template-columns: 2fr 1fr;
    }
}

@media (max-width: 86rem) {
    .research-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 78rem) {
    .tests-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .t-img {
        height: 18rem;
    }

    .integration-row {
        gap: 3rem;
    }
    
    .integration-section {
        max-width: calc((100% - 6rem) / 2);
    }
}

@media (max-width: 66rem) {
    .research-container {
        grid-template-columns: 1fr;
    }

    .t-img {
        height: 16rem;
    }
}

@media (max-width: 52rem) {
    .integration-row {
        gap: 3rem;
    }   

    .integration-section {
        max-width: calc(100%);
    }

    .tests-container {
        grid-template-columns: 1fr;
    }

    .t-img {
        height: 24rem;
    }
}

@media (max-width: 38rem) {
    .t-img {
        height: 16rem;
    }
}

@media (max-width: 26rem) {
    .left-container img {
        min-height: 56.5rem !important;
    }
}