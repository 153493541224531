.tests-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5rem;
}

.tests-section {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.tests-section .bullet li {
    line-height: 1.75rem !important;
}

.tests-section .bullet:hover {
    text-decoration: none;
}

.mini-section {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.t-img {
    width: 100%;
    height: 12rem;
    object-fit: cover;
}

.integration-row {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.integration-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: calc((100% - 10rem) / 3);
    text-align: center;
}

.research-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 3rem;
}

.benefits-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem;
    align-items: flex-start;
    width: 100%;
}

.benefits-sections {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    text-align: left;
}

.si-container {
    z-index: 2;
    position: relative;
    height: calc((100vh - 3.612rem) * (3.3/5));
    text-align: center;
}

.si-container img {
    max-height: calc((100vh - 3.612rem) * (2.95/5));
    width: 100%;
    object-fit: cover;
}

.si-text {
    position: absolute;
    bottom: 10%;
    left: 10%;
    right: 10%;
    color: white;
    z-index: 1;
}

.si-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 100%);
    pointer-events: none;
}

.right-container-mod {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    width: 30vw;
    overflow: visible;
    padding-left: 3rem;
    padding-right: 3rem;
}

.top-icon-row h4 {
    display: inline-block !important;
    margin: 0;
    text-align: left;
}

.top-icon-row img {
    max-height: 2.5rem;
    width: auto;
    padding-right: 0.5rem;
}

@media (max-width: 100rem) {
    .benefits-container {
        grid-template-columns: 1fr 2fr 1fr;
        gap: 3rem;
    }
}

@media (max-width: 98rem) {
    .right-container-mod {
        width: 40vw;
    }
}

@media (max-width: 96rem) {
    .tests-container {
        gap: 3rem;
    }
}

@media (max-width: 86rem) {
    .research-container {
        grid-template-columns: 2fr 1fr;
    }

    .benefits-container {
        grid-template-columns: 1fr;
    }

    .benefits-section {
        order: 1;
    }

    .b-img {
        order: 2;
    }
}

@media (max-width: 78rem) {
    .tests-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .t-img {
        height: 18rem;
    }

    .integration-row {
        gap: 3rem;
    }
    
    .integration-section {
        max-width: calc((100% - 6rem) / 2);
    }

    .research-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 76rem) {
    .right-container-mod {
        width: 50vw;
    }
}

@media (max-width: 66rem) {
    .research-container {
        grid-template-columns: 1fr;
    }

    .t-img {
        height: 16rem;
    }
}

@media (max-width: 62rem) {
    .right-container-mod {
        align-items: center !important;
        gap: 3rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .top-icon-row h4 {
        text-align: center;
        white-space: nowrap;
    }
}

@media (max-width: 52rem) {
    .integration-row {
        gap: 3rem;
    }   

    .integration-section {
        max-width: calc(100%);
    }

    .tests-container {
        grid-template-columns: 1fr;
    }

    .t-img {
        height: 24rem;
    }
}

@media (max-width: 38rem) {
    .t-img {
        height: 16rem;
    }
}

@media (max-width: 30rem) {
    .top-icon-row h4 {
        white-space: wrap;
    }

    .cc-row {
        min-width: 14.35rem;
    }

    .eeo-row, .ee-row {
        min-width: 14.65rem;
    }

    .dt-row {
        min-width: 14.25rem;
    }

    .eeps-row {
        min-width: 13.7rem;
    }

    .ioc-row {
        min-width: 15rem;
    }
}

@media (max-width: 26rem) {
    .left-container img {
        min-height: 30rem !important;
    }

    #lights {
        min-height: 43rem !important;
    }
}