.about-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 3.612rem);
    width: auto;
    position: relative;
}

.left-container {
    position: relative;
    width: 70vw;
    overflow: hidden;
}

.left-container img {
    width: 100%;
    min-height: calc(100vh - 3.612rem);
    height: auto;
    object-fit: cover;
}

.right-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 30vw;
    overflow: visible;
    padding: 3rem;
    height: 100%;
    justify-content: space-between;
}

.right-container .bullet {
    padding-left: 5.5rem;
    text-decoration: none !important;
}

.right-container .bullet li {
    line-height: 2rem !important;
}

.special-header {
    display: flex;
    flex-direction: row;
    gap: 0;
    overflow-wrap: break-word; /* Allow breaking of words */
    word-break: normal; /* Allow breaking of words at any point */
    max-width: 100%; /* Ensure it doesn't overflow */
}

.about-overlay {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 3rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 1;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: left;
}

.about-overlay p:hover {
    text-decoration: none !important;
}

.page-section .top-icon-row h4:hover {
    text-decoration: underline;
}

.page-section .bullet:hover {
    text-decoration: underline !important;
}

.top-icon-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: 0.5rem;
}

.page-section .top-icon-row h4 {
    text-align: left !important;
}

.svg-container {
    position: relative;
    width: 100%;
}

.areas-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
    padding-top: 0;
    width: 100%;
}

.group-overlay {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding: 3rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
}

.icon-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    flex-wrap: wrap;
}

.icon {
    height: 5rem;
    width: 5rem;
}

.leaf-icon {
    height: 4.25rem;
    width: 4.25rem;
}

.padded-area {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.icon-row h4 {
    flex: 1;
}

.partners-container {
    display: flex;
    flex-direction: column;
    gap: 7.5rem;
}

.partners-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    text-align: left;
    align-items: center;
    gap: 1rem;
}

.partners-block, .partners-block-top {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem;
    gap: 2rem;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.partners-row h3 {
    justify-content: center;
    align-items: center;
}

.p-img {
    width: auto;
    height: auto;
    max-height: 2.5rem;
}

.p-img-qualcomm {
    max-height: 2rem;
}

.p-img-mitre {
    max-height: 1.65rem;
}

.p-img-mavenir {
    max-height: 2.15rem;
}

.p-img-dell {
    max-height: 2.75rem;
}

.p-img-nvidia {
    max-height: 2.65rem;
}

.p-img-raytheon {
    max-height: 2.2rem;
}

.p-img-nexcepta {
    max-height: 2.35rem;
}

.portfolio {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    padding-bottom: 6rem;
}

.portfolio-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.elements-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.elements-container .bullet {
    text-decoration: none !important;
}

.elements-container .bullet li {
    line-height: 1.75rem !important;
}

.mini-section {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.mini-section .bullet li {
    text-align: left;
}

.subtitle:hover {
    text-decoration: none !important;
}

.elements-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

@media (max-width: 130rem) {
    .partners-block, .partners-block-top {
        gap: 5rem;
    }
}

@media (max-width: 106rem) {
    .partners-block, .partners-block-top {
        gap: 3rem;
    }
}

@media (max-width: 98rem) {
    .left-container {
        width: 60vw;
    }

    .right-container {
        width: 40vw;
    }

    .special-header {
        flex-direction: column;
    }
}

@media (max-width: 88rem) {
    .about-overlay {
        top: 50%;
    }
}

@media (max-width: 76rem) {
    .left-container {
        width: 50vw;
    }

    .right-container {
        width: 50vw;
    }
}

@media (max-width: 66rem) {
    .portfolio {
        display: flex;
        flex-direction: column;
    }

    .portfolio-text {
        order: 2;
    }

    .elements-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .elements-center .section {
        display: flex;
        width: 100%;
        justify-content: center !important;
        align-items: center !important;
    }

    .mini-section {
        width: 100%;
    }

    .mini-section .bullet {
        width: 100%;
    }

    .partners-row {
        display: flex;
        flex-direction: column;
    }

    .partners-container h3 {
        text-align: center;
    }
}

@media (max-width: 62rem) {
    .areas-container {
        grid-template-columns: 1fr;
    }

    .about-container {
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: center !important;
        justify-content: center !important;
    }

    .left-container {
        width: 100%;
    }

    .right-container {
        gap: 3rem;
        width: 65vw;
    }
}

@media (max-width: 56rem) {
    .partners-header {
        text-align: center !important;
    }

    .elements-container {
        padding-left: 3rem;
    }

    .p-img {
        max-height: 2.25rem;
    }

    .p-img-qualcomm {
        max-height: 1.75rem !important;
    }
    
    .p-img-mitre {
        max-height: 1.4rem !important;
    }
    
    .p-img-mavenir {
        max-height: 1.9rem !important;
    }
    
    .p-img-dell {
        max-height: 2.5rem !important;
    }
    
    .p-img-nvidia {
        max-height: 2.4rem !important;
    }
    
    .p-img-raytheon {
        max-height: 1.95rem !important;
    }

    .p-img-nexcepta {
        max-height: 2.1rem;
    }
}

@media (max-width: 48rem) {
    .elements-container {
        padding-left: 0;
    }

    .right-container {
        width: 100%;
    }
}

@media (max-width: 32rem) {
    .about-overlay {
        padding: 2.5rem;
        gap: 0.5rem;
    }

    .left-container img {
        min-height: 60rem !important;
    }

    /* #col-pic {
        min-height: 56rem !important;
    } */

    .about-container {
        padding-top: 1rem;
    }

    .icon-row {
        flex-direction: column;
        align-items: flex-start;
    }
}