ul li {
    line-height: 1.5rem;
}

.image-container {
    position: relative;
    width: calc(100vw - 24.146rem) !important;
    height: 100% !important;
    position: relative;
}

.image-container img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.home-top {
    position: relative;
    padding-top: 3.612rem;
}

.home-top-link {
    display: inline-block;
    color: var(--color-primary-dark) !important;
}

.home-top-link .subtitle p {
    color: var(--color-primary-dark);
}

.home-top-link:hover {
    text-decoration: underline !important;
}

.full-height {
    height: calc(100vh - 3.612rem);
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.overlay {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 3rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 1;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
}

.overlay-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.overlay p:hover {
    text-decoration: none !important;
}

.menu {
    height: 100% !important;
    display: flex;
}

.home-rest {
    position: relative;
}

.custom-container {
    display: flex;
    flex-direction: column;
    padding: 3rem;
}

.section {
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;
    width: 100%;
    padding-left: 12rem;
    padding-right: 12rem;
}

.section p:hover {
    text-decoration: none !important;
}

.header-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

button {
    white-space: nowrap;
}

.bullets li h6:hover {
    text-decoration: underline;
}

.location-section {
    display: flex;
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.location-container {
    width: 100%;
    flex: 1;
    text-align: center;
}

.location-image {
    width: 100%;
    height: 18rem; /* 288px */
    object-fit: cover;
    margin-bottom: 1.5rem; /* 24px */
}

.location-container p:hover {
    text-decoration: underline !important;
    color: black;
}

.custom-row {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.news-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    width: 100%;
}

.news-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100% !important;
}

.news-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    width: 100%;
}

.news-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.news-container p:hover {
    text-decoration: underline !important;
}

.lg-news {
    width: 100%;
    height: auto;
}

.n-lg-img {
    width: 100%;
    height: auto;
    min-height: 30rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.n-sm-img {
    width: 100%;
    height: auto;
    min-height: 12rem;
    max-height: 12rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.card-text {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
}

.small-card-image {
    width: 100%;
    height: auto;
    min-height: 14rem;
    border-radius: 10px 10px 0 0;
}

.vertical {
    display: flex;
    flex-direction: column;
}

#cert-part {
    text-align: center;
}

.component-container {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-items: center !important;
    justify-content: center !important;
    flex-wrap: wrap;
}

.component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc((100% - 10rem) / 3);
    border-radius: 10px;
    height: auto;
}

.component-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    width: 100%;
    height: auto;
    align-items: flex-start !important;
}

.component-text h4:hover {
    text-decoration: underline !important;
}

.component p:hover {
    text-decoration: underline !important;
}

.c-pic {
    width: 100%;
    min-width: 100%;
    border-radius: 10px 10px 0 0 !important;
}

.logo-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.logo-container img {
    max-width: 100%;
    height: auto;
}

#custom-padding {
    padding: 1.5rem;
}

.mini-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
}

.full-image {
    height: auto;
    flex: 1;
    width: 100% !important;
    min-width: 100% !important;
}

.testimonials {
    display: flex;
    flex-direction: column;
    gap: 6rem;
}

.testimonial-row {
    text-align: left;
    gap: 3rem;
}

.company {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start !important;
}

.odd-testimonial-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 5rem;
    width: 100%;
    align-items: center;
}

.even-testimonial-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5rem;
    width: 100%;
    align-items: center;
}

.equipment {
    display: flex;
    flex-wrap: "wrap";
    padding-top: 6rem;
}

.equipment-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5rem;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
}

.equipment-section {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.equipment-section h6:hover, ul:hover {
    text-decoration: underline;
}

.equipment-section .bullet li {
    line-height: 1.75rem !important;
}

#comp {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.logo-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 3rem;
}

.logo-container img {
    max-width: 100%;
    height: auto;
}

#custom-padding {
    padding: 1.5rem;
}

.mini-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
}

.equipment {
    display: flex;
    flex-wrap: "wrap";
}

.cert-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6rem;
    width: 100%;
    padding-left: 9rem;
    padding-right: 9rem;
}

.cert-container img {
    border-radius: 0 !important;
    max-height: 8rem !important;
    width: auto !important;
    max-width: 100% !important;
    height: auto;
}

.cert-container h5:hover {
    text-decoration: none !important;
}

@media (max-width: 110rem) {
    .lg-news img {
        max-height: 24rem !important;
        min-height: 24rem;
    }
}

@media (max-width: 96rem) {
    .cert-container {
        padding-left: 6rem;
        padding-right: 6rem;
        gap: 3rem;
    }
}

@media (max-width: 86rem) {
    .section {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .lg-news img {
        max-height: 22rem !important;
        min-height: 22rem;
    }
}

@media (max-width: 78rem) {
    .equipment-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .component-container {
        gap: 3rem;
    }

    .news-section img {
        height: 10rem !important;
        min-height: 10rem;
    }
}

@media (max-width: 70rem) {
    .news-container {
        display: flex;
        flex-direction: column;
    }

    .news-section {
        width: 100% !important;
    }

    .news-section img {
        min-height: 18rem;
    }

    .lg-news img {
        max-height: 30rem !important;
        min-height: 30rem;
    }

    .section {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media (max-width: 68rem) {
    .equipment-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .section {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .odd-testimonial-row {
        gap: 3rem;
    }   

    .even-testimonial-row {
        gap: 3rem;
    }

    .cert-container {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 64rem) {
    .overlay {
        top: 50% !important;
        left: 50% !important;
    }

    .full-height {
        flex-direction: column !important;
        order: 1;
    }

    .image-container {
        order: 1;
        min-height: calc(100vh - 3.612rem) !important;
        width: 100% !important;
    }

    .menu-container {
        order: 2;
    }

    .menu-row {
        order: 2;
    }

    .home-rest {
        padding-top: 49.5rem;
    }
}

@media (max-width: 60rem) {
    .location-section {
        grid-template-columns: 1fr !important;
    }

    .location-image {
        height: 22rem;
    }

    .equipment-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .section {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }

    .component-container {
        gap: 5rem;
    }   

    .component {
        max-width: 100%;
    }
}

@media (max-width: 56rem) {
    .cert-container {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 52rem) {
    .news-section img {
        min-height: 16rem;
    }

    .lg-news n-lg-img img {
        height: 26rem !important;
        max-height: 26rem !important;
        min-height: 26rem;
    }
}

@media (max-width: 50rem) {
    .news-section img {
        height: 14rem !important;
        min-height: 14rem;
    }
}

@media (max-width: 48rem) {
    .equipment-container {
        grid-template-columns: 1fr;
    }

    .e-pic {
        width: 100%;
        height: auto;
    }

    .odd-testimonial-row {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        min-width: 100% !important;
    }   

    .even-testimonial-row {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .full-image {
        width: 100% !important;
    }
    
    .image-place {
        width: 100% !important;
        height: auto;
        padding-bottom: 1.5rem;
        order: 1;
    }

    .company {
        order: 2;
    }

    .section {
        padding-top: 3rem;
        padding-bottom: 3.75rem;
    }

    .location-image {
        height: 18rem;
    }

    .lg-news img {
        height: 26rem;
        min-height: 26rem;
    }

    .news-section img {
        height: 12.5rem !important;
        min-height: 12.5rem;
    }

    .home-rest {
        padding-top: 47.5rem;
    }
}

@media (max-width: 40rem) {
    .lg-news img {
        height: 20rem;
        min-height: 20rem;
    }
}

@media (max-width: 36rem) {
    .custom-row {
        flex-direction: column;
        min-width: 100% !important;
    }

    .lg-news img {
        height: 18rem;
        min-height: 18rem;
    }
}

@media (max-width: 32rem) {
    .home-top {
        padding-top: 4.861rem;
    }

    .overlay {
        gap: 0.5rem;
        padding: 2.5rem;
    }

    .overlay-text {
        gap: 0.5rem;
    }

    .lg-news img {
        height: 16rem;
        min-height: 16rem;
    }

    .home-rest {
        padding-top: 48rem;
    }
}

@media (max-width: 26rem) {
    .image-container {
        min-height: 62rem !important;
        max-width: 100% !important;
    }

    .home-rest {
        padding-top: 66rem !important;
    }
}

@media (max-width: 22rem) {
    .image-container {
        min-height: 68rem !important;
    }
}