.booking {
    text-align: center;
    padding: 3rem;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.35);
    width: 100%;
}

.booking:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
}

.custom-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding-top: 3rem;
}

.form-row {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
}

.text-input-field {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid black;
    width: 100%;
    box-sizing: border-box;
}

.text-input-field:placeholder {
    color: var(--color-secondary-dark);
    font-family: "Source Sans 3";
    font-size: 1rem; /* 16px */
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 24px */
}

.lg-btn {
    background: var(--color-primary-dark);
    color: white;
    border: none;
    display: inline-flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    line-height: 1;
    width: 50%;
}

.lg-btn:hover {
    background: var(--color-primary-darkest);
    color: white;
}

.booking p:hover {
    text-decoration: none !important;
}

.email-label {
    display: inline;
    color: var(--color-primary-dark);
}

.email-label:hover {
    text-decoration: underline;
}

@media (max-width: 98rem) {
    .desc-text {
        text-align: left;
    }
}

@media (max-width: 42rem) {
    .custom-form {
        gap: 1.5rem;
    }
    
    .form-row {
        display: flex;
        flex-direction: column;
    }
}