.footer {
    color: white;
    background-color: black;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: flex-start;
    gap: 5rem;
    padding: 6rem;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.footer-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    padding: 0rem;
    align-items: flex-start;
    gap: 0.75rem;
}

.footer-section p {
    text-align: left;
}

.footer-section a {
    text-decoration: none;
    text-align: left;
    color: white;
}

.footer-section span {
    color: white;
    text-align: left;
    text-decoration: none;
}

.footer-section a:hover span {
    text-decoration: underline;
    color: white;
}

.footer-section h5:hover {
    text-decoration: none !important;
}

.icon-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    color: white;
}

.icon-container p:hover {
    text-decoration: underline !important;
    color: white;
}

.email {
    color: white;
}

.email:hover {
    text-decoration: underline;
}

.acknowledgement {
    text-align: left;
    text-decoration: none;
    background-color: black;
    /* color: white; */
    color: var(--color-secondary-medium);
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 6rem;
}

.acknowledgement:hover {
    text-decoration: none !important;
}

@media (max-width: 90rem) {
    .footer {
        grid-template-columns: repeat(5, 1fr);
        gap: 3rem;
    }
}

@media (max-width: 80rem) {
    .footer {
        grid-template-columns: repeat(4, 1fr);
        gap: 5rem;
        padding: 5rem;
    }

    .acknowledgement {
        padding: 5rem;
    }
}

@media (max-width: 73rem) {
    .footer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 62rem) {
    .footer {
        grid-template-columns: repeat(2, 1fr);
        gap: 4rem;
        padding: 4rem;
    }

    .acknowledgement {
        padding: 4rem;
    }
}

@media (max-width: 38rem) {
    .footer {
        grid-template-columns: 1fr;
        padding: 2.5rem;
        gap: 2.5rem;
    }

    .footer-section {
        gap: 0.5rem;
    }

    .acknowledgement {
        padding: 2.5rem;
    }
}