.section-header-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 6rem;
    box-sizing: border-box;
    justify-content: center;
}

.line-container {
    flex: 1;
    display: flex;
    align-items: center;
}

.line-svg {
    width: 100%;
}

.section-header-container h2 {
    margin: 0 1.5rem;
    text-align: center !important;
    display: inline-block;
}

@media (max-width: 48rem) {
    .section-header-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .section-header-container h2 {
        margin: 0 1rem;
        display: inline-block;
    }
}

@media (max-width: 36rem) {
    .line-container {
        display: none;
    }
}