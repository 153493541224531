.nav-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    padding: 1rem 2rem; /* 16px 32px */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-secondary-light);
    box-shadow: inset 0 -2px 8px rgba(0, 0, 0, 0.1);
}

.nav-bar-left {
    list-style: none;
    display: flex;
    gap: 1.5rem; /* 24px */
    margin: 0;
    padding: 0;
    align-items: center;
}

.nav-bar-right {
    list-style: none;
    display: flex;
    gap: 6rem; /* 96px */
    margin: 0;
    padding: 0;
    text-decoration: none !important;
}

.nav-link h6 {
    text-decoration: none;
    white-space: nowrap;
    margin: 0;
    line-height: 1.25rem !important;
}

.nav-link:hover {
    text-decoration: underline !important;
    text-decoration-color: inherit;
}

.nav-link h6:hover {
    color: var(--color-primary-dark) !important;
    text-decoration: underline;
    text-decoration-color: var(--color-primary-dark) !important;
}

.logo {
    height: 1.5rem; /* 24px */
    width: auto;
}

@media (max-width: 50rem) {
    .nav-container {
        display: flex;
        flex-direction: column;
        padding: 1rem 6rem;
        gap: 1rem;
        width: 100%;
    }

    .nav-bar-right {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        width: 100%;
        gap: 0;
    }
}

@media (max-width: 38rem) {
    .nav-container {
        display: flex;
        flex-direction: column;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: 100%;
    }

    .nav-bar-right {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        width: 100%;
    }
}