.menu-row {
    display: flex;
    flex-direction: row;
}

.menu-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: inline-block;
    transition: transform 0.3s ease;
    z-index: 500;
}

.menu-container.shifted {
    transform: translateX(-100%);
    margin-right: 3rem;
}

.menu-content {
    display: inline-block;
    height: 100%;
    padding: 3rem;
    background: white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}

.menu-button {
    background-color: white !important;
    border: none;
    padding: 0 !important;
    line-height: 3rem !important;
    color: black !important;
}

.menu-button h6, ul li button h6 {
    color: black !important;
}

#indent {
    margin-left: 3rem;
    text-align: left;
}

#double-indent {
    margin-left: 6rem;
    text-align: left;
}

#indent-less {
    margin-left: 1.5rem;
    text-align: left;
}

#double-indent-less {
    margin-left: 3rem;
    text-align: left;
}

.bullets, .shorter-bullets {
    text-decoration: none !important;
    color: black !important;
}

.bullets li {
    color: black !important;
    line-height: 3rem !important;
}

.shorter-bullets li {
    line-height: 2rem !important;
}

.testing-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
}

.equipment-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 7rem;
    width: auto;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.e-img {
    height: 100%;
    width: auto;
}

.system-integration {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.horizontal {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

@media (max-width: 64rem) {
    .menu-row {
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 3rem;
    }

    .menu-container {
        position: relative;
        display: flex;
        transition: none !important;
        z-index: 500;
        height: 100%;
    }

    .menu-container.shifted {
        transform: translateX(0);
        margin-right: 0;
    }
}

@media (max-width: 55rem) {
    .menu-container {
        z-index: 499;
        width: 22.541rem;
        height: 48.594rem;
    }
}

@media (max-width: 32rem) {
    .equipment-images {
        height: 6.25rem;
    }
}

@media (max-width: 26rem) {
    .menu-container {
        height: 52rem;
    }
}